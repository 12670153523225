<template>
  <div class="cw-footer">
    <v-row>
      <v-col
        md="4"
        cols="12"
      >
        <div>
          <v-row>
            <ul class="cw-footer__list cw-footer__list--top">
              <li class="text-body-2">
                <b>Tact Finance AB</b>
              </li>
              <li class="text-caption">
                c/o The Park<br>
                Hälsingegatan 49
              </li>
              <li class="text-caption">
                113 31 Stockholm
              </li>
            </ul>
            <ul class="cw-footer__list cw-footer__list--bottom">
              <li class="text-caption">
                <b v-translate class="mr-2">Phone</b>
                <a
                  id="cw-footer__tel"
                  href="tel:0846501931"
                  @click="$eventLogger.clickEvent($event);"
                >
                  08 465 019 31
                </a>
              </li>
              <li class="text-caption">
                <b v-translate class="mr-2">Email</b>
                <a
                  id="cw-footer__mailto"
                  href="mailto:kundtjanst@saldo.com"
                  @click="$eventLogger.clickEvent($event);"
                >
                  kundtjanst@saldo.com
                </a>
              </li>
              <li class="text-caption">
                <b v-translate>Business id</b>&nbsp;556796-8077
              </li>
            </ul>
          </v-row>
        </div>
      </v-col>
      <v-col
        md="4"
        cols="12"
      />
      <v-col
        md="4"
        cols="12"
      >
        <ul class="cw-footer__list">
          <li
            v-translate="{
              openingTag: '<b>',
              closingTag: '</b>',
              openingTag2: '<i>',
              closingTag2: '</i>',
            }"
            class="text-caption"
            render-html="true"
          >
            %{ openingTag }Tact Finance Ab%{ closingTag } is registered in the Financial
            Supervisory Authority company register with the registration number
            %{ openingTag2 }44039%{ closingTag2 }.
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'CwFooter',
};
</script>
